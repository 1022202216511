



























































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import ViewDataPlato from "./view_data_plato.vue";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { EstructuraBasicaGeneracionAutomaticaDieta } from "@/shared/dtos/visualizacion_dieta/EstructuraBasicaGeneracionAutomaticaDieta";
@Component({
  components: {},
})
export default class dialog_select_equivalente extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("item", { type: Object }) syncit!: dieta_visualizacion;
  @PropSync("equivalentes", { type: Object })
  syncequivalentes!: EstructuraBasicaGeneracionAutomaticaDieta[];
  @PropSync("id_paciente") id_pacientesync!: number;
  public created() {}
  public get color_item() {
    switch (this.syncit.id_tipo_dato) {
      case 1:
        return "title_plato";
        break;
      case 2:
        return "title_suplemento";
        break;
      case 3:
        return "title_alimento";
        break;
    }
  }

  public getDuplicate() {
    return new dieta_visualizacion(this.syncit);
  }
  public intercambiar(x: EstructuraBasicaGeneracionAutomaticaDieta) {
    this.syncit.nombre = x.nombre;
    this.syncit.id_estructura = x.id_estructura;

    switch (this.syncit.id_tipo_dato) {
      case 1:
        this.syncit.id_estructura = x.id_estructura;
        this.syncit.cantidad = 0;
        break;
      case 2:
        return "title_suplemento";
        break;
      case 3:
        if (this.syncit.cantidad <= 0) {
          this.syncit.cantidad == 100;
        }
        break;
    }

    dieta_visualizacionModule
      .modificarplato_dieta(this.getDuplicate())
      .then((x: dieta_visualizacion) => {
        this.syncit.cantidad = x.cantidad;
        dieta_visualizacionModule.getdieta_estadisticas({
          id_dieta: this.syncit.id_dieta,
          id_paciente:
            this.id_pacientesync === undefined ? 2 : this.id_pacientesync,
        });
      });
    this.syncedDialog = false;
  }
}
